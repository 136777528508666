import * as React from 'react'
import { Box } from '@chakra-ui/react'

import ModalVideoComponent from '../ModalVideo'
import FreeForeverSection from './FreeForeverSection'
import FindBetterApplicantsSection from './FindBetterApplicantsSection'
import StepsSection from './StepsSection'
import Testimonial from './Testimonal'
import WhyUse from './WhyUse'
import ScreenHero from './ScreenHero'

const UseCases = ({ useCaseData }) => {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <Box>
      <ModalVideoComponent
        isOpen={isOpen}
        setOpen={setOpen}
        videoId={useCaseData.screenHero.demoYoutubeVideoId}
      />
      <ScreenHero props={useCaseData.screenHero} openVideoModal={() => setOpen(true)} />
      <WhyUse props={useCaseData.whyManagerUseFaceFile} />
      <Testimonial props={useCaseData.whyManagerUseFaceFile.testimonial} />
      <StepsSection props={useCaseData.stepsSection} />
      <FreeForeverSection props={useCaseData.freeForeverSection} />
      <FindBetterApplicantsSection props={useCaseData.findBetterApplicantsSection} />
    </Box>
  )
}
export default UseCases
